import React from 'react';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import DOMPurify from 'dompurify';

function Sidebar1({ title, description, button, url, background, image, imageAlt }) {
    const bgStyle = background ? { background } : null;

    return (
        <div className="sidebar-widget sidebar1" style={bgStyle}>
            <h4 className="widget-title">{title}</h4>
            <div className="widget-content">
                <div className="map-outer mb-0">
                    {image && (
                        <img
                            className="border-arrondi mb-2"
                            src={image}
                            alt={imageAlt || 'image'}
                        />
                    )}
                    {description && (
                        <p>{Parser(DOMPurify.sanitize(description))}</p>
                    )}
                    {button && (
                        <a
                            href={url}
                            className="theme-btn btn-style-six small mt-3"
                            aria-label={button}
                        >
                            {button}
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
}

Sidebar1.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.string,
    url: PropTypes.string,
    background: PropTypes.string,
    image: PropTypes.string,
    imageAlt: PropTypes.string,
};

Sidebar1.defaultProps = {
    title: '',
    description: '',
    button: '',
    url: '#',
    background: '',
    image: '',
    imageAlt: 'image',
};

export default Sidebar1;
