import React, { useState, useEffect } from 'react';
import { AnimatePresence } from "framer-motion";
import ProductCard from "../ProductCard";
import CardSkeleton from "../CardSkeleton";

export default function FormationFilterTab ({products, category}){

    const [filterData, setFilterData] = useState(products);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");
    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        setFilterData(products);
    }, [products]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);


    /*const handleSearch = (e) => {
        setSearch(e.target.value);
        if (e.target.value === "") {
            setFilterData(products);
        } else {
            const filtered = products?.filter((product) =>
                    product.titre.toLowerCase().includes(search.toLowerCase())
            );
            setFilterData(filtered);
        }
    };*/

    const handleFilter = (selectedCategory) => {
        setActiveCategory(selectedCategory);

        if (selectedCategory === null) {
            setFilterData(products);
        } else {
            const filtered = products?.filter(product =>
                    product.categorie.some(cat => cat.titre === selectedCategory)
            );
            setFilterData(filtered);
        }
    };

    return(
        <>
            {/*<div className="flex justify-center items-center mt-4">
                <input
                        type="text"
                        placeholder="Rechercher une formation"
                        onChange={(e) => handleSearch(e)}
                        name="search"
                        className="border-2 border-gray-300 p-2 rounded-md max-w-xs w-full"
                />
            </div>*/}
            <div className="default-tabs tabs-box">
                {category && category.length > 0 ? (
                        <ul className="tab-buttons justify-content-center">
                            <li
                                    key="tab-all"
                                    className={`tab-btn ${activeCategory === null ? 'active-btn' : ''}`}
                                    onClick={() => handleFilter(null)}
                            >
                                Toutes les formations
                            </li>
                            {category.map((cat) => (
                                    <li
                                            key={`tab-${cat.id}`}
                                            className={`tab-btn ${activeCategory === cat.titre ? 'active-btn' : ''}`}
                                            onClick={() => handleFilter(cat.titre)}
                                    >
                                        {cat.titre}
                                    </li>
                            ))}
                        </ul>
                ) : (
                        ''
                )}

                <div className="tabs-content wow fadeInUp animated">
                    <div className="tab active-tab">
                        <div className="row">
                            <div className="content-side col-lg-12 col-md-12 col-sm-12">
                                <div className="blog-grid">
                                    <div className="row justify-content-center">
                                        {filterData?.map((product) => (
                                            <AnimatePresence key={product.id}>
                                                {loading ? <CardSkeleton /> :
                                                    <ProductCard product={product} colLg="4"/>
                                                }
                                            </AnimatePresence>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

