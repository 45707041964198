import React, { useState, useCallback, useMemo } from 'react';
import Parser from 'html-react-parser';
import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';

const CLASS_NAMES = {
    ACTIVE: 'active',
    ACTIVE_BLOCK: 'active-block',
    CURRENT: 'current',
};

function FAC({ data, title, description }) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = useCallback((index) => {
        setActiveIndex(prevIndex => prevIndex === index ? null : index);
    }, []);

    const parsedTitle = useMemo(() => title ? Parser(title) : null, [title]);
    const parsedDescription = useMemo(() => description ? Parser(description) : null, [description]);

    return (
            <section className="faqs-section">
                <div className="auto-container">
                    <div className="text-center">
                        {parsedTitle && <h2 className="libre-title mb-5">{parsedTitle}</h2>}
                        {parsedDescription && <div className="text mt-9">{parsedDescription}</div>}
                    </div>
                    <ul className="accordion-box">
                        {data?.map((content, contentIndex) => (
                            <li className={`accordion block ${activeIndex === contentIndex ? CLASS_NAMES.ACTIVE_BLOCK : ''}`} key={contentIndex}>
                                <div className={`acc-btn ${activeIndex === contentIndex ? CLASS_NAMES.ACTIVE : ''}`} onClick={() => handleClick(contentIndex)}>
                                    {content.titre && typeof content.titre === 'string' && Parser(content.titre)}
                                    <span className="icon flaticon-add"/>
                                </div>
                                <AnimatePresence initial={false}>
                                    {activeIndex === contentIndex && (
                                        <div key="content" className={`acc-content ${CLASS_NAMES.CURRENT}`}>
                                            <div className="content">
                                                {content.description && typeof content.description === 'string' && (
                                                    <h4 className="counter-title">
                                                        {Parser(content.description)}
                                                    </h4>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </AnimatePresence>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
    );
}

FAC.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        titre: PropTypes.string,
        description: PropTypes.string,
    })),
    title: PropTypes.string,
    description: PropTypes.string,
};

export default FAC;
