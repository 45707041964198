import React, {useEffect, useState} from 'react';
import axios from "axios";
import Parser from "html-react-parser";
import JeuCard from "./JeuCard";

const JeuBlocks = ({entrepriseId, title ,description}) => {
    const [entreprise, setEntreprise] = useState([]);
    const [jeux, setJeux] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${entrepriseId}`)
                setEntreprise(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/jeu_programmes?jeu_entreprise=${entrepriseId}&statut=1&`);
                setJeux(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <section className="news section">
                <div className="auto-container">
                    <div className="text-center libre ">
                        {title ? (
                            <h2 className="libre-title">
                                {Parser(title)}
                            </h2>) : ''}
                        {description ? (<div className="text mt-9">
                            {Parser(description)}
                        </div>) : ''}
                    </div>
                    {/* End sec-title */}

                    <div className="sidebar-page-container">
                        <div className="auto-container">
                            <div className="row wow fadeInUp animated">
                                <div className="content-side col-lg-12 col-md-12 col-sm-12">
                                    <div className="blog-grid">
                                        <div className="row justify-content-center">
                                            {jeux && jeux.map((jeu, index) => (
                                                jeu.iframeJeu && jeu.iframeJeu.trim() !== '' &&
                                                <JeuCard key={index} jeu={jeu}/>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* End .default-tabs */}
                </div>
            </section>
        </>
    );
};

export default JeuBlocks;
