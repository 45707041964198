import React, {useEffect, useState} from 'react';
import axios from "axios";
import Parser from "html-react-parser";
import ContactModal from "../ContactModal";
import {motion} from "framer-motion";

const CTAPageFormation = ({formationId, content}) => {
    const [ctaData, setCtaData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=14`);
                setCtaData(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
            <>
                {ctaData?.map((home, homeIndex) => (
                        <React.Fragment key={homeIndex}>
                            {home?.frontPageContainers?.map((container, containerIndex) => (
                                container?.demandeInformation && (
                                    <section
                                        className="call-to-action-two"
                                        style={{
                                            backgroundImage: `url(${container.backgroundUrl})`,
                                            backgroundPosition: 'top',
                                        }}
                                        key={containerIndex}
                                    >
                                        <div className="auto-container" style={{filter: 'brightness(100%)', WebkitFilter: 'brightness(100%)', margin: '0'}}>
                                            <div className="sec-title light text-center">
                                                <h2>{Parser(container.titre)}</h2>
                                                <div className="text" style={{color: "#ffffff"}}>{Parser(container.description)}</div>
                                            </div>

                                            <motion.div
                                                    initial={{ opacity: 0, y: 0 }}
                                                    animate={{ opacity: 1, y: 20 }}
                                                    transition={{ delay: 1.0, duration: 1.5 }}
                                            >
                                                <ContactModal formationId={formationId} formationName={content.titre} type={1} textButton="Demande d’informations"/>
                                            </motion.div>
                                        </div>
                                    </section>
                                )
                            ))}
                        </React.Fragment>
                    ))}
            </>
    );
};

export default CTAPageFormation;
