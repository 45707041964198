import React from 'react';

const CardSkeleton = () => {
    return (
            <div className="job-block col-lg-4 col-md-12 col-sm-12">
                <div className="inner-box">
                    <hr className="mt-1 mb-1"/>
                    <hr className="mt-2 mb-2"/>
                    <hr className="mt-3 mb-3"/>
                    <hr className="mt-4 mb-4"/>
                    <hr className="mt-5 mb-5"/>
                    <hr className="mt-6 mb-6"/>
                </div>

            </div>
    );
    /*return Array(cards)
                    .fill(0)
                    .map((_, i) => (
                        <div className="card-skeleton" key={i}>
                            <div className="left-col">
                                <Skeleton circle width={40} height={40}/>
                            </div>
                            <div className="right-col">
                                <Skeleton count={4} style={{marginBottom: ".6rem"}}/>
                            </div>
                        </div>
                    ));*/
};

export default CardSkeleton;
