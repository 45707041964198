import Parser from "html-react-parser";

export const truncateText = (text, caractere, wordLimit, format) => {
    if (text) {
        if (text.length > caractere) {
            const words = text.split(' ');
            if (wordLimit && (words.length > wordLimit)) {
                text = words.slice(0, wordLimit).join(' ') + ' ...';
            } else if(wordLimit && (words.length < wordLimit)) {
                text = words.slice(0, wordLimit).join(' ');
            } else {
                text = text.substring(0, caractere) + ' ...';
            }
        }

        if (format === 2) {
            return text;
        }
        return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
    }
    return '';
};

const extractTextContent = (parsedHtml) => {
    if (Array.isArray(parsedHtml)) {
        return parsedHtml.map(el => el.props ? el.props.children : el).join('');
    }
    if (parsedHtml.props) {
        return parsedHtml.props.children;
    }
    return parsedHtml;
};

export const cleanAndTruncateText = (htmlString, truncateLength, truncateLines) => {
    const parsedHtml = Parser(htmlString);
    const textContent = extractTextContent(parsedHtml);
    return truncateText(textContent, truncateLength, truncateLines);
};
