/*export const getCategory = async () => {
    let category;
    const url = process.env.REACT_APP_API_BASE_URL + "/categories?statut=1";
    const options = {
        method: "GET",
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        category = result['hydra:member'];
    } catch (error) {
        console.error(error);
    }

    return category;
};*/

import axios from 'axios';

export const getCategory = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/categories?statut=1`);
        const category = response.data;
        return category;
    } catch (error) {
        console.error('Erreur lors de la récupération des catégories :', error);
        throw error;
    }
};