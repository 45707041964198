import React from 'react';
import TestinomyCard from "./testinomyCard";

function Testimonies({ index, temoignages, background }) {
    return (
            <section className="about-section-two bgc-home19-2" key={index} style={{backgroundImage: `url("${background}")`}}>
                <div className="image-voile" style={{display: "none"}}></div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto wow fadeInUp" data-wow-delay="300ms">
                            <div className="sec-title text-center">
                                <h2>Nos clients parlent de nos formations</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 m-auto wow fadeInUp" data-wow-delay="500ms">
                            <TestinomyCard testimonies={temoignages} />
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Testimonies;
