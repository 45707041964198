import React, { useState, useEffect } from 'react';
import Parser from "html-react-parser";

const Tabs = ({ data, activeTabId }) => {
    const filteredData = data.filter(chapitre => chapitre.description);
    const [activeTab, setActiveTab] = useState(activeTabId || filteredData[0]?.id);

    useEffect(() => {
        if (activeTabId !== undefined && activeTabId !== null) {
            setActiveTab(activeTabId);
        }
    }, [activeTabId]);

    return (
            <div className="default-tabs style-two tabs-box">
                <ul className="tab-buttons clearfix">
                    {filteredData.map((chapitre) => (
                            <li
                                    key={chapitre.id}
                                    className={`tab-btn ${activeTab === chapitre.id ? 'active-btn' : ''}`}
                                    onClick={() => setActiveTab(chapitre.id)}
                            >
                                {chapitre.titre}
                            </li>
                    ))}
                </ul>

                <div className="tabs-content libre">
                    {filteredData.map((chapitre) => (
                            <div
                                    key={chapitre.id}
                                    className={`tab ${activeTab === chapitre.id ? 'active-tab' : ''}`}
                                    id={`tab-${chapitre.id}`}
                            >
                                <p>{Parser(chapitre.description)}</p>
                            </div>
                    ))}
                </div>
            </div>
    );
};

export default Tabs;