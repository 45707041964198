import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import FormModel from "./form/FormModel";
import {Link} from "react-router-dom";

function ContactModal({formationId, sessionId, sessionName, sessionDate, textButton, showText = true, formationName, small, linkPageContact}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
            <>
                {linkPageContact ? (
                    <Link
                        to="/contact"
                        className={`theme-btn btn-style-six ${small}`}
                    >{textButton}
                    </Link>
                ): (
                    <a href="#" className={`theme-btn btn-style-six ${small}`} onClick={handleShow}>
                        {textButton}
                    </a>)
                }


                <Modal show={show} onHide={handleClose} backdrop={true} centered size='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="shadow-title blue">Contacter Talents Handicap Formation</div>
                        {sessionName && sessionDate && showText && (
                            <div className="sec-title text-center">
                                <div className="text">
                                    <p>Vous souhaitez obtenir des renseignements sur la session de formation inter-entreprise :</p>
                                    <span>{sessionName}</span>
                                    <div className="date">
                                        <img src="/images/icons/clock.png" alt="icon" width="30"/>
                                        <p>{sessionDate}</p>
                                    </div>
                                    <p>Notre équipe est là pour vous accompagner ! Remplissez le formulaire ci-dessous, et nous vous répondrons dans les plus brefs délais.</p>
                                </div>
                            </div>
                        )}
                        {formationId && formationName && (
                            <div className="sec-title text-center">
                                <div className="text">
                                    <p>Vous souhaitez obtenir des renseignements sur la formation:</p>
                                    <span>{formationName}</span>
                                    <p>Notre équipe est là pour vous accompagner ! Remplissez le formulaire ci-dessous,
                                        et nous vous répondrons dans les plus brefs délais.</p>
                                </div>
                            </div>
                        )}

                        <div className="default-form">
                            <FormModel handleClose={handleClose} formationId={formationId} sessionId={sessionId} type={2}/>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
    );
}

export default ContactModal;