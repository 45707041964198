import React from 'react';
import Parser from 'html-react-parser';

function Metrics({ data, background }) {
    const bgStyle = background ? { background } : {};
    return (
            <section className="about-section style-two" style={{padding: "0", ...bgStyle}}>
                <div className="auto-container">
                    <div className="text-center">
                        {data.titre ? (
                            <h2 className="libre-title">
                                {Parser(data.titre)}
                            </h2>) : ''}
                        {data.description ? (<div className="text mt-9">
                            {Parser(data.description)}
                        </div>) : ''}
                    </div>
                    <div className="fun-fact-section">
                        <div className="row">
                            {data?.map((content, contentIndex) => (
                                <div className="counter-column col-lg-4 col-md-4 col-sm-12 wow fadeInUp"
                                     data-wow-delay="800ms" key={contentIndex}>
                                    <div className="count-box">
                                        {content.titre && (
                                            <div>{typeof content.titre === 'string' ? Parser(content.titre) : ''}</div>)}
                                    </div>
                                    {content.description && (
                                        <div className="counter-title">{typeof content.description === 'string' ? Parser(content.description) : ''}</div>)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Metrics;
