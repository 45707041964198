import React from "react";
import FooterDefault from "../../components/footer/common-footer";
import SessionsPageApi from "./sessionsPageApi";

const FormationsPage = () => {
    return (
            <>
                <SessionsPageApi />
                <FooterDefault footerStyle="style-six alternate -type-11" />
            </>
    );
};


export default FormationsPage;

