import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Skeleton from "react-loading-skeleton";

function QuiSommesAPi() {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=2`);
                setPages(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
            <>
                {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
                            <Skeleton count={5} height={50} width={800} baseColor="#f7f7f7" highlightColor="#f7f7f7" duration={2} enableAnimation={true} />
                        </div>
                ) : (
                        pages.length > 0 && <Page page={pages[0]} />
                )}
            </>
    );
}

const Page = React.memo(({ page }) => {
    const addClassToUl = (html) => {
        return html.replace(/<ul>/g, '<ul class="list-style-three">');
    };
    return (
            <section className="about-section-three">
                <div className="auto-container">
                    <div className="text-box">
                        <h4>{page.titre.charAt(0).toUpperCase() + page.titre.slice(1).toLowerCase()}</h4>
                        <p dangerouslySetInnerHTML={{ __html: addClassToUl(page?.description) }} />
                        {page?.image?.slice(0, 1).map(img => <img key={img.id} src={img.urlS3} alt={img.name} />)}
                    </div>
                </div>
            </section>
    );
});

export default QuiSommesAPi;
