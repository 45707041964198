import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookIcon,
    XIcon,
    LinkedinShareButton, LinkedinIcon, EmailIcon
} from 'react-share';

const Social = ({ url, title, description }) => {

  /*return (
    <div className="social-links">
      {socialContent.map((item) => (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          key={item.id}
        >
          <i className={`fab ${item.icon}`}></i>
        </a>
      ))}
    </div>
  );*/

  return (
          <div className="social-links" style={{gap: '5px'}}>
            <FacebookShareButton url={url} quote={description}>
                <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={title}>
                <XIcon size={32} round />
            </TwitterShareButton>

              <LinkedinShareButton url={url}>
                  <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <EmailShareButton
                      url={url}
                      subject={title}
                      body="body"
                      className="Demo__some-network__share-button"
              >
                  <EmailIcon size={32} round />
              </EmailShareButton>
          </div>
  );
};

export default Social;
