import React from "react";
import FormationsPageAPi from "./formationsPageApi";
import FooterDefault from "../../components/footer/common-footer";

const FormationsPage = () => {
    return (
            <>
                <FormationsPageAPi />
                <FooterDefault footerStyle="style-six alternate -type-11" />
            </>
    );
};


export default FormationsPage;

