import React from 'react';
import {BrowserRouter as Router, Routes, Route, useLocation} from "react-router-dom";
import { motion, AnimatePresence } from 'framer-motion';
import HomePage from "./pages/home/home-7";
import FormationSinglePage from "./pages/formation-single-v1/[slug]";
import ContactPage from "./pages/contact";
import DemanderUnDevis from "./pages/demanderUnDevis";
import About from "./pages/quiSommesNous";
import FormationsPage from "./pages/formation/FormationsPage";
import JeuInclusion from "./pages/jeuInclusion";
import JeuSinglePage from "./pages/jeu-single/[slug]";
import EntreprisePageAPi from "./pages/entreprise/entreprise";
import GeneratePassword from "./pages/generatePassword";
import SessionsPage from "./pages/formation/SessionsPage";
import SessionSinglePage from "./pages/session-single-v1/[slug]";
import ScrollToTop from "./utils/ScrollToTop";
import Page from "./pages/page";
import {CookieConsent, Cookies} from "react-cookie-consent";
import SiteMap from "./pages/siteMap";
import GoogleAnalytics from "./GoogleAnalytics";

const PageTransition = ({ children }) => {
    return (
        <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

const AnimatedRoutes = () => {
    const location = useLocation();

    return (
            <AnimatePresence mode="wait">
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<PageTransition><HomePage /></PageTransition>} />
                    <Route path="/contact" element={<PageTransition><ContactPage /></PageTransition>} />
                    <Route path="/nos-formations" element={<PageTransition><FormationsPage /></PageTransition>} />
                    <Route path="/nos-formations/:formationId/:formationSlug" element={<PageTransition><FormationSinglePage /></PageTransition>} />
                    <Route path="/sessions" element={<PageTransition><SessionsPage /></PageTransition>} />
                    <Route path="/sessions/:sessionId/:sessionSlug" element={<PageTransition><SessionSinglePage /></PageTransition>} />
                    <Route path="/start/:entrepriseId/:entrepriseSlug" element={<PageTransition><EntreprisePageAPi /></PageTransition>} />
                    <Route path="/start/:entrepriseId/:entrepriseSlug/generate/password" element={<PageTransition><GeneratePassword /></PageTransition>} />
                    <Route path="/pourquoi-nous-choisir/qui-sommes-nous" element={<PageTransition><Page type={17} /></PageTransition>} />
                    <Route path="/pourquoi-nous-choisir/formation-pourquoi-pour-qui" exact element={<PageTransition><Page type={25} /></PageTransition>} />
                    <Route path="/pourquoi-nous-choisir/certification-qualiopi" element={<PageTransition><Page type={18} /></PageTransition>} />
                    <Route path="/pourquoi-nous-choisir/methodes-deroulement" element={<PageTransition><Page type={16} /></PageTransition>} />
                    <Route path="/pourquoi-nous-choisir/technologie-accessibilite" element={<PageTransition><Page type={19} /></PageTransition>} />
                    <Route path="/solutions-sensibilisation" element={<PageTransition><Page type={22} /></PageTransition>} />
                    <Route path="/resources/guides" element={<PageTransition><Page type={20} /></PageTransition>} />
                    <Route path="/resources/faq" element={<PageTransition><Page type={21} /></PageTransition>} />
                    <Route path="/jeu-inclusion" element={<PageTransition><JeuInclusion /></PageTransition>} />
                    <Route path="/jeu-inclusion/:jeuId/:jeuSlug" element={<PageTransition><JeuSinglePage /></PageTransition>} />
                    <Route path="/jour-entreprise" element={<PageTransition><Page type={23} /></PageTransition>} />
                    <Route path="/demander-un-devis" element={<PageTransition><DemanderUnDevis /></PageTransition>} />
                    <Route path="/qui-sommes-nous" element={<PageTransition><About /></PageTransition>} />
                    <Route path="/politique-confidentialite-donnees" element={<PageTransition><Page type={12} /></PageTransition>} />
                    <Route path="/mentions-legales" element={<PageTransition><Page type={11} /></PageTransition>} />
                    <Route path="/cgu" exact element={<PageTransition><Page type={24} /></PageTransition>} />
                    <Route path="/dispositifs-financement" exact element={<PageTransition><Page type={26} /></PageTransition>} />
                    <Route path="/plan-site" exact element={<PageTransition><SiteMap /></PageTransition>} />
                </Routes>
            </AnimatePresence>
    );
};
function App() {
    const handleAccept = () => {
        console.log('Cookies acceptés');
    };
    const handleDecline = () => {
        console.log('Cookies refusés');
        Cookies.remove('myAwesomeCookieName');
    };

   /* if (loading) {
        return <LoaderSpinner />;
    }*/

    return (
            <Router>
                <GoogleAnalytics />
                <ScrollToTop />
                {/*<Suspense fallback={<LoaderSpinner />}>

                </Suspense>*/}
                <AnimatedRoutes />
                <CookieConsent
                        location="bottom"
                        buttonText="J'accepte"
                        declineButtonText="Je refuse"
                        cookieName="myAwesomeCookieName"
                        style={{ background: "#2B373B" }}
                        buttonStyle={{ background: "#DEF2D7", color: "#5B7052", fontSize: "13px", borderRadius: "50px", padding: "6px 15px" }}
                        declineButtonStyle={{ background: "#ECC8C5", color: "#AB3331", borderRadius: "50px", padding: "6px 15px", fontSize: "13px"}}
                        expires={60}
                        onAccept={handleAccept}
                        onDecline={handleDecline}
                        enableDeclineButton
                        flipButtons={1}
                >
                    Ce site utilise des cookies pour améliorer l'expérience utilisateur.{" "}
                    <a href="/politique-confidentialite-donnees" style={{ color: "#ffffff" }}>En savoir plus</a>
                </CookieConsent>
            </Router>
    );
}

export default App;
