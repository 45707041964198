import React, {useState} from "react";
import {Slide, toast, ToastContainer} from "react-toastify";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import RequiredLabel from "./RequiredLabel";

function FormModel({handleClose, formationId, sessionId, type } ){

    const [sujet, setSujet] = useState('');
    const [phone, setPhone] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [entreprise, setEntreprise] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [nbr_participants, setParticipants] = useState('');

    const [sujetError, setSujetError] = useState('');
    const [nomError, setNomError] = useState('');
    const [prenomError, setPrenomError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [entrepriseError, setEntrepriseError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [participantsError, setParticipantsError] = useState('');

    const url = formationId ? `${process.env.REACT_APP_API_BASE_URL}/front_formations/${formationId}` : null;
    const urlSession = sessionId ? `${process.env.REACT_APP_API_BASE_URL}/formations/${sessionId}` : null;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setNomError('');
        setSujetError('');
        setPrenomError('');
        setEmailError('');
        setPhoneError('');
        setEntrepriseError('');
        setMessageError('');
        setParticipantsError('');

        if (type === 1 && !sujet.trim()) {
            setSujetError('Veuillez choisir l\'objet.');
            return false;
        }

        if (!nom.trim()) {
            setNomError('Veuillez entrer votre nom.');
            return false;
        }

        if (!prenom.trim()) {
            setPrenomError('Veuillez entrer votre prénom.');
            return false;
        }

        if (!email.trim()) {
            setEmailError('Veuillez entrer une adresse e-mail.');
            return false;
        }

        if (!emailRegex.test(email)) {
            setEmailError('Veuillez entrer une adresse e-mail valide.');
            return false;
        }

        if (!entreprise.trim()) {
            setEntrepriseError('Veuillez entrer le nom de votre entreprise.');
            return false;
        }

        if (type !== 2 && !phone.trim()) {
            setPhoneError('Veuillez entrer un numéro valide.');
            return false;
        }

        if (!message.trim()) {
            setMessageError('Veuillez entrer un message.');
            return false;
        }

        if (type === 2 && !nbr_participants.trim()) {
            setParticipantsError('Veuillez entrer le nombre de participants souhaité.');
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true);

        const formData = {
            sujet: sujet || 'Demande de contact',
            message,
            phone,
            nom,
            prenom,
            entreprise,
            type,
            nbr_participants,
            sender: email,
            ...(url ? { module: url } : {}),
            ...(urlSession ? { session: urlSession } : {})
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/contacts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                console.log('Données envoyées avec succès');
                toast.success('Votre message a été envoyé avec succès !', {
                    onClose: () => {
                        if (typeof handleClose === 'function') {
                            handleClose();
                        }
                        setSujet('');
                        setPhone('');
                        setNom('');
                        setPrenom('');
                        setEntreprise('');
                        setEmail('');
                        setMessage('');
                        setParticipants('');
                        setIsSubmitting(false);
                    },
                });
            } else {
                console.error('Une erreur est survenue lors de l\'envoi des données');
                toast.error('Une erreur est survenue lors de l\'envoi des données.');
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error('Une erreur est survenue lors de la requête :', error);
            toast.error('Une erreur est survenue lors de la requête.');
            setIsSubmitting(false);
        }
    };

    return(
            <>
                <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        transition={Slide}
                />
                <Form>
                    <Row>
                        {type === 1 && (
                                <Form.Group className="form-group mb-3" controlId="formSubject">
                                    <Form.Label><RequiredLabel label="Objet de votre demande" /></Form.Label>
                                    <Form.Control
                                            as="select"
                                            value={sujet}
                                            autoFocus
                                            placeholder="Demande d’informations"
                                            onChange={(e) => setSujet(e.target.value)}
                                            className="custom-placeholder"
                                    >
                                        <option></option>
                                        <option value="Demande d’informations">Demande d’informations</option>
                                        <option value="Demande de rendez-vous">Demande de rendez-vous</option>
                                        <option value="Demande de devis">Demande de devis</option>
                                        <option value="Inscription à une formation">Inscription à une formation</option>
                                        <option value="Informations sur nos solutions de sensibilisation">Informations sur nos solutions de sensibilisation</option>
                                        <option value="Accessibilité">Accessibilité</option>
                                        <option value="Assistance technique">Assistance technique</option>
                                        <option value="Autres">Autres</option>
                                    </Form.Control>
                                    {sujetError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{sujetError}</div>}
                                </Form.Group>
                        )}
                        <Col md={6}>
                            <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label><RequiredLabel label="Nom" /></Form.Label>
                                <Form.Control
                                        type="text"
                                        name="nom"
                                        placeholder="Votre nom"
                                        required={true}
                                        value={nom}
                                        className="custom-placeholder"
                                        onChange={(e) => setNom(e.target.value)}
                                />
                                {nomError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{nomError}</div>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label><RequiredLabel label="Prénom" /></Form.Label>
                                <Form.Control
                                        type="text"
                                        name="prenom"
                                        placeholder="Votre prénom"
                                        value={prenom}
                                        required={true}
                                        className="custom-placeholder"
                                        onChange={(e) => setPrenom(e.target.value)}
                                />
                                {prenomError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{prenomError}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label><RequiredLabel label="E-mail" /></Form.Label>
                        <Form.Control
                                type="email"
                                name="sender"
                                placeholder="Votre e-mail"
                                value={email}
                                required={true}
                                className="custom-placeholder"
                                onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{emailError}</div>}
                    </Form.Group>
                    <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label><RequiredLabel label="Entreprise" /></Form.Label>
                        <Form.Control
                                type="text"
                                name="entreprise"
                                value={entreprise}
                                placeholder="Votre entreprise"
                                required={true}
                                className="custom-placeholder"
                                onChange={(e) => setEntreprise(e.target.value)}
                        />
                        {entrepriseError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{entrepriseError}</div>}
                    </Form.Group>
                    <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput4">
                        <Form.Label>Téléphone</Form.Label>
                        <Form.Control
                                type="text"
                                name="phone"
                                value={phone}
                                placeholder="Votre numéro de téléphone"
                                className="custom-placeholder"
                                onChange={(e) => setPhone(e.target.value)}
                                required={type !== 2}
                        />
                        {phoneError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{phoneError}</div>}
                    </Form.Group>
                    {type === 2 && (
                            <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput5">
                                <Form.Label><RequiredLabel label="Nombre de participants souhaité" /></Form.Label>
                                <Form.Control
                                        type="text"
                                        name="nbr_participants"
                                        value={nbr_participants}
                                        className="custom-placeholder"
                                        onChange={(e) => setParticipants(e.target.value)}
                                />
                                {participantsError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{participantsError}</div>}
                            </Form.Group>
                    )}
                    <Form.Group className="form-group mb-3" controlId="exampleForm.ControlTextarea6">
                        <Form.Label><RequiredLabel label="Message" /></Form.Label>
                        <Form.Control
                                as="textarea"
                                placeholder="Votre message"
                                rows={3}
                                value={message}
                                required={true}
                                className="custom-placeholder"
                                onChange={(e) => setMessage(e.target.value)}
                        />
                        {messageError && <div style={{ color: '#AB3331', fontStyle: 'italic', fontSize: 'small' }}>{messageError}</div>}
                    </Form.Group>
                </Form>

                <Button
                        className="theme-btn btn-style-six"
                        onClick={handleSubmit}
                        style={{border: 'none'}}
                        disabled={isSubmitting}
                >
                    {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
                </Button>
            </>
    )
}

export default FormModel;