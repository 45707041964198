import React, {useEffect, useState} from 'react';
import axios from "axios";
import Parser from "html-react-parser";
import ContactModal from "../ContactModal";

const CalendrierSessions = ({formationId, formationName, background}) => {
    const bgStyle = background ? { background } : {};
    const [ctaData, setCtaData] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(false);

    const cleanHTML = (html) => {
        // Replace nested <p> tags with <div> or other suitable tags
        const cleanedHTML = html
                .replace(/<p[^>]*>(.*?)<\/p>/g, '<div>$1</div>'); // Replace all <p> with <div>
        return cleanedHTML;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_pages?type=14`);
                setCtaData(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/formationsinterbyformation/${formationId}`);
                setSessions(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [formationId]);

    return (
            <>
                {ctaData?.map((home, homeIndex) => (
                    <React.Fragment key={home.id || homeIndex}>
                        {home?.frontPageContainers?.map((container, containerIndex) => (

                            sessions?.length > 0 && container?.formationsInterEntrepriseV2 && (
                                <section className="testimonial-section layout-pb-60 layout-pt-60" key={container.id || containerIndex} style={bgStyle}>
                                    <div className="container-fluid">

                                            {container.titre ? (
                                                    <div className="sec-title text-center mb-4"><h2
                                                        className="shadow-title">{Parser(cleanHTML(container.titre))}</h2></div>)
                                                        : ''}
                                                        {container.description ? (
                                                                <div className="text-center">
                                                                    <div
                                                                        className="text">{Parser(cleanHTML(container.description))}
                                                                        <div className="sec-title text-center mb-4">
                                                                            <div className="text">
                                                                                <span>{Parser(cleanHTML(formationName))}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                    ) : ''}

                                        <div className="calendar">
                                            <div className="calendar-grid">
                                                {sessions?.map((session, sessionIndex) => (
                                                    <div key={session.id || sessionIndex}
                                                         className="session-card-bottom ">
                                                        <div className="date">
                                                            <span><img src="/images/icons/clock.png" alt="icon" style={{width: "28px", height: "28px" }}/></span>
                                                            <p>{session.dateHeure}</p>
                                                        </div>
                                                        <ContactModal sessionId={session.id} type={2}
                                                              sessionName={session.titre}
                                                              sessionDate={session.dateHeure}
                                                              textButton="Cette session m'intéresse"
                                                              small="small"
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )
                        ))}
                    </React.Fragment>
                ))}
            </>
    );
};

export default CalendrierSessions;
