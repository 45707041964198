import React, { useState, useEffect } from "react";
import FooterDefault from "../../components/footer/common-footer";
import MobileMenu from "../../components/header/MobileMenu";
import Seo from "../../components/common/Seo";
import Social from "../../components/formation-single-pages/social/Social";
import { useParams } from 'react-router-dom';
import ContactModal from "../../components/ContactModal";
import Parser from 'html-react-parser';
import Header from "../../components/home-7/Header";

const SessionSinglePage = () => {
    const { sessionId, sessionSlug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState();

    useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + `/formations/${sessionId}`)
        .then((res) => res.json())
        .then((response) => {
          setData(response);
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
  }, [sessionId, sessionSlug]);

    const cleanHTML = (html) => {
        const cleanedHTML = html
                .replace(/<\/?p>/g, '') // Supprime les balises <p> et </p>
                .replace(/<ul>/g, '<ul class="list-style-three">')
                .replace(/\n/g, '') // Supprime les sauts de ligne
                .replace(/\r/g, '') // Supprime les retours chariot
                .trim(); // Supprime les espaces en début et fin de chaîne

        const styledHTML = `<div style="text-align: left;">${cleanedHTML}</div>`;

        return Parser(styledHTML);
    };

  return (
    <>
      <Seo pageTitle={(data?.titre || '').charAt(0).toUpperCase() + (data?.titre || '').slice(1).toLowerCase()} pageUrl={window.location.href} />

      {/* <!-- Header Span --> */}
      <span className="header-span"></span>

      <Header />
      {/* <!--End Main Header --> */}

      <MobileMenu />
      {/* End MobileMenu */}

      {/* <!-- Job Detail Section --> */}
      {!isLoading && (
      <section className="candidate-detail-section">
        <div className="upper-box">
          <div className="auto-container">
            <div className="candidate-block-five">
              <div className="inner-box">
                <div className="content" style={{paddingLeft: 0}}>
                    <h4 className="name">
                        {(data?.titre || '').charAt(0).toUpperCase() + (data?.titre || '').slice(1).toLowerCase()}
                    </h4>

                  <ul className="post-tags">
                      {/*{data?.categorie?.map((cat) => (
                        <li key={cat.id}>{cat.titre}</li>
                      ))}*/}
                  </ul>
                </div>

                <div className="btn-box">
                    <ContactModal sessionId={sessionId} type={3} textButton="Contactez-nous" />
                </div>
              </div>
            </div>
            {/*  <!-- Candidate block Five --> */}
          </div>
        </div>
        {/* <!-- Upper Box --> */}

        <div className="candidate-detail-outer">
          <div className="auto-container">
            <div className="row">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="text">
                  <div className="video-outer">
                      <div className="video-box">
                          <figure className="image">
                              <div className="play-now">
                                  {data?.image?.slice(0, 1).map(img => <img key={img.id} src={img.urlS3} alt={img.name} />)}
                              </div>
                          </figure>
                      </div>
                  </div>
                  {/* <!-- About Video Box --> */}
                    <div className="mb-4">{data && data.description ? cleanHTML(data.description) : ''}</div>
                </div>
              </div>
              {/* End .content-column */}

              <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar">
                  <div className="sidebar-widget social-media-widget">
                    <h4 className="widget-title">Partager</h4>
                    <div className="widget-content">
                      <div className="social-links">
                        {/*<Social />*/}
                          <Social url={`/sessions/${sessionId}/${sessionSlug}`} title={data?.titre} description={data?.description} />
                      </div>
                    </div>
                  </div>
                  {/* End .sidebar-widget social-media-widget */}

                  {/*<div className="sidebar-widget">
                    <h4 className="widget-title">Professional Skills</h4>
                    <div className="widget-content">
                      <ul className="job-skills">
                        <JobSkills />
                      </ul>
                    </div>
                  </div>*/}
                  {/* End .sidebar-widget skill widget */}

                  {/*<div className="sidebar-widget contact-widget">
                    <h4 className="widget-title">Contact Us</h4>
                    <div className="widget-content">
                      <div className="default-form">
                        <Contact />
                      </div>
                    </div>
                  </div>*/}
                  {/* End .sidebar-widget contact-widget */}
                </aside>
                {/* End .sidebar */}
              </div>
              {/* End .sidebar-column */}
            </div>
          </div>
        </div>
        {/* <!-- job-detail-outer--> */}
      </section>
      )}
      {/* <!-- End Job Detail Section --> */}
        <FooterDefault footerStyle="style-six alternate -type-11" />
      {/* <!-- End Main Footer --> */}

    </>
  );
};

export default SessionSinglePage;
