/*export const getProducts = async () => {
    let products;
    const url = process.env.REACT_APP_API_BASE_URL + "/front_formations";
    const options = {
        method: "GET",
    };

    try {
        const response = await fetch(url, options);
        const result = await response.json();
        products = result['hydra:member'];
    } catch (error) {
        console.error(error);
    }

    return products;
};*/

import axios from 'axios';

export const getProducts = async () => {
    try {
        const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_formations?statut=1`);
        const product = response.data;
        return product;
    } catch (error) {
        console.error('Erreur lors de la récupération des catégories :', error);
        throw error;
    }
};