import React, { useMemo } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Parser from 'html-react-parser';
import { truncateText } from "../utils/wordsLimit";
import ContactModal from "./ContactModal";

export default function SessionCard({ session }) {
    const {
        id,
        titre = "",
        intro = "",
        jour = "",
        moisAb = "",
        idModule = "",
        slugModule = "",
        image,
        Heure = "",
        dateHeure = ""
    } = session || {};

    const sessionId = `${id}`;
    const truncatedTitle = useMemo(() => truncateText(titre, 50, 12, 2), [titre]);
    const truncatedDescription = useMemo(() => truncateText(intro, 35, 25, 2), [intro]);

    return (
        <motion.div
            className="job-block col-lg-6 col-md-12 col-sm-12"
            layout
            initial={{ opacity: 0.6, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
        >
            <div className="inner-box">
                <div className="content d-flex">
                    <div className="date">
                        {jour}
                        <span>{moisAb}</span>
                    </div>
                    <h4 className="session-card">
                        <Link to={`/nos-formations/${idModule}/${slugModule}`} title={titre}>
                            {truncatedTitle && Parser(truncatedTitle)} <br/>
                        </Link>
                    </h4>
                    <span className="company-logo">
                        {image && image.lien && (
                            <img src={image.lien} alt={image.nom || 'Image de la session'} style={{borderRadius: "18px"}} />
                        )}
                    </span>
                </div>

                <div className="session-card-description" style={{lineHeight: "22px"}}>
                    {truncatedDescription && Parser(truncatedDescription)}
                </div>
                <div className="session-card-bottom">
                    <div className="d-flex gap-2">
                        <img src="/images/icons/clock.png" alt="icône d'horloge" style={{width: "28px", height: "28px" }}/>
                        <b>{Heure}</b>
                    </div>
                    <motion.a
                        href={`/nos-formations/${idModule}/${slugModule}`}
                        className="theme-btn btn-style-one small"
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                    >
                        Découvrir la formation
                    </motion.a>
                    <motion.div
                        initial={{ opacity: 0, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5, duration: 0.5 }}
                    >
                        <ContactModal
                            sessionId={sessionId}
                            sessionDate={dateHeure}
                            sessionName={titre}
                            type={2}
                            textButton="Cette session m'intéresse"
                            small="small"
                        />
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
}
