import {Link, useLocation} from "react-router-dom";
import {
    isActiveLink,
    isActiveParentChild,
} from '../../utils/linkActiveChecker';
import mobileMenuData from '../../data/mobileMenuData';
import React from "react";
function  Breadcrumb ({ title = "", meta = "", formation = false }) {
    const location = useLocation();
    const removeBr = (html) => {
        return html.replace(/<br\s*\/?>/g, ' ');
    };
    return (

        <div className="title-outer">
          <ul className="page-breadcrumb">
            <li>
              <Link to="/">Accueil</Link>
            </li>
              {mobileMenuData.map((item) => (
                  item.items && item.items.length > 0 ? (
                      <>
                          {isActiveParentChild(item.items, location.pathname) && <li key={item.id}>{item.label}</li>}
                          {item.items.map((subItem) => (
                              location.pathname.includes(subItem.routePath) ?
                                  formation ? (
                                      <>
                                          <li key={item.id}>{item.label}</li>
                                          <li key={subItem.routePath}><Link to={subItem.routePath}>{subItem.name}</Link>
                                          </li>
                                          <li>{removeBr(meta)}</li>
                                      </>
                                  ) : (
                                      <li key={subItem.routePath}>{subItem.name}</li>
                                  )

                                  : null
                          ))}
                      </>
                  ) : (
                      isActiveLink(item.routePath, location.pathname) && <li key={item.id}><Link to="/">{item.label}</Link></li>
                  )
              ))}
          </ul>
        </div>

  );
}

export default Breadcrumb;
