const defaultImages = [
    'images/formations/1.png',
    'images/formations/2.png',
    'images/formations/3.png',
    'images/formations/4.png',
    'images/formations/5.png',
    'images/formations/6.png',
    'images/formations/7.png',
    'images/formations/8.png',
    'images/formations/9.png',
    'images/formations/10.png',
];

export default defaultImages;