import React from "react";

const RequiredLabel = ({ label }) => (
        <span>
        {label}
            <span style={{ color: 'red' }}> *</span>
    </span>
);

export default RequiredLabel;
