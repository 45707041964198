import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  isActiveLink,
  isActiveParentChild,
} from '../../utils/linkActiveChecker';
import mobileMenuData from '../../data/mobileMenuData';
import {getSessions} from "../../lib/session";
import {useQuery} from "@tanstack/react-query";

const HeaderNavContent = () => {
    const location = useLocation();

    const { data: sessions, isLoading } = useQuery({
        queryKey: ['sessions'],
        queryFn: getSessions,
    });

    const filterMenuData = (menuData) => {
        return menuData.map((item) => {
            if (item.items) {
                return {
                    ...item,
                    items: item.items.filter((subItem) => {
                        if (subItem.routePath === '/sessions') {
                            return sessions && sessions.length > 0;
                        }
                        return true;
                    }),
                };
            }
            return item;
        });
    };

    const filteredMenuData = filterMenuData(mobileMenuData);

    if (isLoading) {
        return <p>Chargement...</p>;
    }

  return (
      <nav className="nav main-menu">
        <ul className="navigation" id="navbar">

          {filteredMenuData.map((item) => (
            item.items && item.items.length > 0 ? (
              <li
                key={item.id}
                className={`dropdown ${isActiveParentChild(item.items, location.pathname) ? "current" : ""}`}
              >
                <span>{item.label}</span>
                <ul>
                  {item.items.map((subItem) => (
                    <li
                      key={subItem.routePath}
                      className={location.pathname.includes(subItem.routePath) ? "current" : ""}
                    >
                      <NavLink to={subItem.routePath}>{subItem.name}</NavLink>
                    </li>
                  ))}
                </ul>

              </li>
            ) : (
              <li
                key={item.id}
                className={`${isActiveLink(item.routePath, location.pathname) ? "current" : ""}`}
              >
                <NavLink to={item.routePath}>
                    {item.icon ? (
                        isActiveLink(item.routePath, location.pathname)
                            ? <img src={item.iconActive} alt={item.label} width="20" />
                            : <img src={item.icon} alt={item.label} width="20" />
                    ) : (
                        item.label
                    )}
                </NavLink>
              </li>
            )
          ))}
        </ul>
      </nav>
    );
  };


export default HeaderNavContent;
