import React from 'react';
import Slider from "react-slick";
import {AnimatePresence} from "framer-motion";

function Partenaires({ partenaires }) {
    const settings = {
        dots: false,
        className: "center",
        centerMode: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1200,

        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                },
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
            <ul className="sponsors-carousel">
                <Slider {...settings} arrows={false}>
                    {partenaires?.map((partenaire) => (
                            <AnimatePresence key={partenaire.id}>
                                <li className="slide-item" key={partenaire.id}>
                                    <figure className="image-box">
                                        {partenaire.image.map(img => <a href="#" key={img.id}><img src={img.urlS3} alt={img.name} /></a>)}
                                    </figure>
                                </li>
                            </AnimatePresence>
                    ))}
                </Slider>
            </ul>
    );
}

export default Partenaires;
