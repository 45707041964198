import React from 'react';
import Parser from 'html-react-parser';
import ModalText from "../ModalText";

function Sidebar2({title, description,button, url, index, background, image, imageAlt, guide = false}) {
    const bgStyle = background ? { background } : {};
    const guideDescription = "<div class='libre text-left' style=\"color:#696969\">\n" +
        "            <p>Bienvenue dans le Jeu de l'inclusion, une expérience interactive et engageante conçue pour vous familiariser avec la diversité et l'inclusion. Voici comment vous pouvez participer et profiter pleinement de cette expérience.</p>\n" +
        "\n" +
        "<h5><strong>Comment participer au jeu</strong></h5>\n" +
        "\n" +
        "<p><strong>Identification </strong>: Une fois sur la page du jeu, il est important d'entrer votre pseudo, votre adresse e-mail, et le code de votre équipe si vous jouez en groupe. " +
        "Voici pourquoi ces informations sont essentielles :</p>\n" +
        "\n" +
        "<ul style=\"margin-left:40px\">\n" +
        "\t<li>Pseudo : Votre identifiant unique dans le jeu. Chaque joueur doit utiliser un pseudo unique. Si vous êtes bloqué avec un pseudo, il est probable qu'un autre joueur l'ait déjà utilisé. Dans ce cas, choisissez un autre pseudo. Notez qu'avec un pseudo, vous pouvez jouer une fois au jeu. Pour rejouer, il faudra utiliser un nouveau pseudo.</li>\n" +
        "\t<li>E-mail : Pour recevoir les notifications, et votre score final.</li>\n" +
        "\t<li>Code de l'équipe : Si vous jouez en équipe, ce code permet de vous associer à votre groupe. Une équipe doit être constituée de 5 joueurs pour être prise en compte.</li>\n" +
        "\t<li>Générer le code d'équipe : Si vous jouez pour une entreprise, vous pouvez générer votre code d'équipe sur la page dédiée de l'entreprise. Cliquez sur le lien fourni par votre entreprise, puis suivez les instructions pour générer le code.</li>\n" +
        "</ul>\n" +
        "\n" +
        "<h5><strong>Jouer sur ordinateur</strong></h5><p>L'expérience du jeu sur mobile n'étant pas optimale, nous vous recommandons de jouer sur ordinateur ou tablette.</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n" +
        "\n" +
        "<h5><strong>Interactivités dans le jeu</strong></h5>\n" +
        "\n" +
        "<ul>\n" +
        "\t<li><strong>Éléments interactifs</strong> : Pour découvrir les éléments interactifs du jeu, appuyez sur la main située en haut à droite de l'écran <img alt=\"\" src=\"/images/icons/main.png\" style=\"height:24px; width:24px\"></li>\n" +
        "\t<li><strong>Quiz </strong>: Une fois que vous avez répondu à un quiz, vous ne pouvez pas le rejouer. Réfléchissez bien avant de répondre !</li>\n" +
        "\t<li><strong>Scores</strong> : Votre score sera envoyé par e-mail à la fin du jeu, après le jour 5. Assurez-vous que votre adresse e-mail est correcte pour recevoir cette information.</li>\n" +
        "</ul>\n" +
        "\n" +
        "<p>L'expérience du jeu sur mobile n'étant pas optimale, nous vous recommandons de jouer sur ordinateur ou tablette.&nbsp;</p>\n" +
        "\n" +
        "<p>&nbsp;</p>\n" +
        "\n" +
        "<h5><strong>Conclusion</strong></h5>\n" +
        "\n" +
        "<p>Nous espérons que vous apprécierez cette expérience ludique et enrichissante. Votre participation contribue à promouvoir un environnement plus inclusif et diversifié.</p>\n" +
        "\n" +
        "<p style=\"text-align:center\">Bon jeu !</p>\n" +
        "        </div>";

    return (
         <div className="sidebar-widget" style={bgStyle} key={index}>
            <h4 className="widget-title">{title}</h4>
            <div className="widget-content">
                <div className="map-outer mb-0">
                    {description && (<p>{Parser(description)}</p>)}
                    {image && (
                        <img
                            src={image}
                            className="border-arrondi mb-2"
                            alt={imageAlt ? ({imageAlt}) : 'image' }
                        />
                    )}
                    {button && (
                        <a href={url}
                           className="theme-btn btn-style-six small mt-3"
                           style={{margin: 'auto', display: 'block', width: 'fit-content'}}>
                            {button}
                        </a>
                    )}
                    {guide && (
                        <ModalText
                            titre="Consulter le guide d’utilisation"
                            text={guideDescription}
                            textButton="Consulter le guide d’utilisation"
                            small="small"
                        />
                    )}
                </div>
            </div>
         </div>

    );
}

export default Sidebar2;
