import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormationSection from "../components/accueil/formations";

const FormationDataComponent = ({ container, containerIndex, nbrItem }) => {
    const [formationData, setFormationData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/front_formations?statut=1`);
                setFormationData(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
            <React.Fragment key={`banner-${containerIndex}`}>
                <FormationSection
                        nbrItem={nbrItem}
                        container={container}
                        formationData={formationData}
                        containerIndex={containerIndex}
                />
            </React.Fragment>
    );
};

export default FormationDataComponent;