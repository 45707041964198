import React, {useEffect, useState} from 'react';
import axios from "axios";
import Parser from "html-react-parser";
import {AnimatePresence} from "framer-motion";
import CardSkeleton from "./CardSkeleton";
import SessionCard from "./SessionCard";

const CalendrierGlobalSessions = ({title, description}) => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/formationsinter`);
                setSessions(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
            <>
                <section className="news section">
                    <div className="auto-container layout-pb-60">
                        <div className="text-center">
                            <h2 className="libre-title">
                                {title ? Parser(title) : ""}
                            </h2>
                            <div className="text mt-9">
                                {description ? Parser(description) : ""}
                            </div>
                        </div>
                    </div>

                    <div className="auto-container">
                        <div className="row wow fadeInUp animated">
                            {sessions?.map((session, sessionIndex) => (
                                    <AnimatePresence key={sessionIndex}>
                                        {loading ? <CardSkeleton/> :
                                                <SessionCard session={session}/>
                                    }
                                </AnimatePresence>
                            ))}
                        </div>
                        <a href="/sessions" className="theme-btn btn-style-six"
                           style={{margin: 'auto', display: 'block', width: 'fit-content'}}>
                            Voir toutes les sessions inter-entreprises
                        </a>
                    </div>
                </section>
            </>
    );
};

export default CalendrierGlobalSessions;
