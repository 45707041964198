import React from 'react';
import Slider from "react-slick";
import Parser from "html-react-parser";

const TestinomyCard = ({ testimonies }) => {
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
    };

    return (
            <Slider {...settings} arrows={false}>
                <div className="testimonial-style2">
                    <div className="tab-content" id="pills-tabContent">
                        {testimonies.map((item) => (
                            <div className="tab-pane fade show active" key={item.id}>
                                <div className="testi-content text-md-center">
                                    <div className="thumb" style={{margin: "0 auto"}}>
                                        {item?.image?.length > 0 ? <img key={item.image[0].id} src={item.image[0].urlS3} alt={item.image[0].alt} /> : null}
                                    </div>

                                    <span className="icon fas fa-quote-left"></span>
                                    <h4 className="testi-text">{Parser(item.description)}</h4>
                                    <h6 className="name">{item.personne}</h6>
                                    <p className="design">{item.fonction}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Slider>
    );
};

export default TestinomyCard;