import React, { useState, useEffect } from "react";
import FooterDefault from "../components/footer/common-footer";
import MobileMenu from "../components/header/MobileMenu";
import Seo from "../components/common/Seo";
import { useParams } from 'react-router-dom';
import Header from "../components/home-7/Header";
import Breadcrumb from "../components/common/Breadcrumb";

const GeneratePassword = () => {
    const { entrepriseId, entrepriseSlug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [data, setData] = useState();
    const [code, setCode] = useState('');
    const url = process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${entrepriseId}`;

    const generateCode = () => {
        const characters = '0123456789';
        let code = '';
        for (let i = 0; i < 5; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    }

    const handleGenerateCode = async () => {
        const newCode = generateCode();
        setCode(newCode);

        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/jeu_entreprise_codes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password: newCode,
                    jeu_entreprise: url,
                }),
            });

            if (!response.ok) {
                throw new Error('Une erreur s\'est produite lors de l\'enregistrement du code');
            }

            console.log('Code enregistré avec succès');
        } catch (error) {
            console.error('Erreur :', error);
        }
    }

    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsPageLoaded(true);
        }, 2000);

        return () => clearTimeout(loadTimeout);
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + `/jeu_entreprises/${entrepriseId}`)
                .then((res) => res.json())
                .then((response) => {
                    setData(response);
                    if (response.erreur === 1) {
                        window.location.replace("/");
                    }
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
    }, [entrepriseId, entrepriseSlug]);


    return (
            <>
                <Seo pageTitle={data?.nom} />

                {/* <!-- Header Span --> */}
                <span className="header-span"></span>

                <Header />
                {/* <!--End Main Header --> */}

                <Breadcrumb title={data?.nom} meta={data?.jeu_entreprise.nom} />

                <MobileMenu />
                {/* End MobileMenu */}

                {/* <!-- Job Detail Section --> */}
                {!isLoading && (
                        <section className="blog-single jeu">
                            <div className="auto-container">
                                <div className="upper-box">
                                    <h3>{data.nom}</h3>
                                </div>
                            </div>

                            <div className="auto-container mt-5 d-flex justify-content-center">
                                <button className="theme-btn btn-style-three" onClick={handleGenerateCode}>Générer un code</button>
                                <p className="block">Code : {code}</p>
                                {data?.jeuEntrepriseCodes.map((code, index) => (
                                    <div className="table-outer">
                                        <table className="default-table">
                                            <thead>
                                            <tr>
                                                <th>Code</th>
                                                <th>Création</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr>
                                                <td>Design UX and UI</td>
                                                <td>14</td>
                                                <td>3</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        </section>
                )}
                {/* <!-- End Job Detail Section --> */}

                {isPageLoaded && (
                    <FooterDefault footerStyle="style-six alternate -type-11" />
                )}
                {/* <!-- End Main Footer --> */}

            </>
    );
};

export default GeneratePassword;
