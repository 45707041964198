import React from "react";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {motion} from "framer-motion";

export default function JeuCard({ jeu }) {
    if (!jeu) return null;

    const jeuId = `${jeu.id}`;
    const jeuSlug = `${jeu.slug}`;

    const handleClick = (e) => {
        if (!jeu.ouvert) {
            e.preventDefault();
            Swal.fire({
                icon: 'error',
                title: 'Jeu inaccessible',
                text: 'Ce jeu est actuellement fermé et inaccessible.',
                confirmButtonText: 'OK'
            });
        }
    };
    return (

        <motion.div
            className={`news-block col-lg-4 col-md-6 col-sm-12`}
            style={{padding: "0 35px"}}
            layout
            initial={{opacity: 0.6, y: 50}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0}}
            transition={{duration: 0.4}}
        >
            <div>
                <div className="image-box">
                    <Link to={`/jeu-inclusion/${jeuId}/${jeuSlug}`} title={jeu.nom} onClick={handleClick}>
                        <span
                            className={`statut ${jeu.ouvert ? 'ouvert' : 'ferme'}`}>{jeu.ouvert ? 'Ouvert' : 'Fermé'}</span>
                        <figure className="image col4">
                            {jeu?.vignettes?.slice(0, 1).map(img => (
                                <img key={img.id} src={img.urlS3} alt={img.alt}/>
                            ))}
                        </figure>
                    </Link>
                </div>
                <div className="lower-content" style={{maxWidth: "364px", textAlign:"center"}}>
                    <h3 className="titleJeu">
                        <Link to={`/jeu-inclusion/${jeuId}/${jeuSlug}`} title={jeu.nom} onClick={handleClick}>
                            {jeu.nom}
                        </Link>
                    </h3>
                    {jeu?.date ?(<div className="dateJeu">{jeu.date}</div>) :''}
                    <Link to={`/jeu-inclusion/${jeuId}/${jeuSlug}`} className={`${jeu.ouvert ? 'm-1 btn-padding-20 theme-btn btn-style-six small' : 'm-1 btn-padding-20 theme-btn btn-style-three small'}`} onClick={handleClick}>Jouer</Link>
                </div>
            </div>
        </motion.div>
    )
    ;
}
