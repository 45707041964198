import React, {useEffect, useState} from "react";
import Seo from "../components/common/Seo";
import Breadcrumb from "../components/common/Breadcrumb";
import MobileMenu from "../components/header/MobileMenu";
import Header from "../components/home-7/Header";
import FooterDefault from "../components/footer/common-footer";
import JeuInclusionAPi from "./jeu/jeuInclusion";

const JeuInclusion = () => {
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        const loadTimeout = setTimeout(() => {
            setIsPageLoaded(true);
        }, 2000);

        return () => clearTimeout(loadTimeout);
    }, []);
    return (
            <>
                <Seo pageTitle="Jeu de l'inclusion" pageUrl={window.location.href} />
                <span className="header-span"></span>
                <Header />
                <MobileMenu />
                <Breadcrumb title="Jeu de l'inclusion" meta="Jeu de l'inclusion" />
                <JeuInclusionAPi />
                {isPageLoaded && (
                    <FooterDefault footerStyle="style-six alternate -type-11" />
                )}
            </>
    );
};


export default JeuInclusion;

