import {Link} from "react-router-dom";

const SidebarFooter = () => {
/*  const socialContent = [
    { id: 1, icon: "fa-facebook-f", link: "https://www.facebook.com/talents.handicap" },
    { id: 2, icon: "fa-x-twitter", link: "https://twitter.com/TalentsHandicap" },
    { id: 3, icon: "fa-instagram", link: "https://www.instagram.com/talents.handicap" },
    { id: 4, icon: "fa-linkedin-in", link: "https://www.linkedin.com/company/talents-handicap/mycompany/" },
  ];*/
    const handleClickGestion = () => {

        const currentUrl = window.location.href;

        // Rediriger vers le site Symfony avec l'URL en paramètre de requête
        window.location.href = process.env.REACT_APP_API_BASE_URL2+`?lastPage=${encodeURIComponent(currentUrl)}`;

    };
  return (
      <div className="mm-add-listing mm-listitem pro-footer ">
          <button className="theme-btn btn-style-four mm-listitem__text" onClick={handleClickGestion}>Formateurs / Apprenants</button>
          <Link to="/contact" className="theme-btn btn-style-six mm-listitem__text ">
              Demander un devis
          </Link>
          {/* job post btn */}

          <div className="mm-listitem__text">
              <div className="contact-info">
                  {/*<span className="phone-num">
            <span>Par téléphone</span>
            <Link to="tel:1234567890">123 456 7890</Link>
          </span>
          <span className="address">
            77 chemin du Val Fleuri, 06800 Cagnes-sur-Mer
          </span>
          <Link to="mailto:support@superio.com" className="email">
            contact@talentshandicap-formation.com
          </Link>*/}
              </div>
              {/* End .contact-info */}

              {/*<div className="social-links">
          <Social />
        </div>*/}
              {/* End social-links */}
          </div>
          {/* End .mm-listitem__text */}
      </div>
  );
};

export default SidebarFooter;
