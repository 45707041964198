import React from 'react';


const FormationPageSidebar = ({data, cleanHTML, formationSlug, formationId}) => {

    const combinedCategories = [
        ...(data?.prerequis || []),
    ];

    return (
            <>
                <div className="widget">
                    <div className="widget-content">
                        <div className="ls-outer">
                            {/* Combined categories */}
                            {combinedCategories.length > 0 ? (
                                <div className="candidate-block-three">
                                    <div className="inner-box" style={{display: "grid"}}>
                                        {combinedCategories.map((option) => (
                                            <div className="content mb-1 mt-1">
                                                <div className="block block1">
                                                    <figure className="image">
                                                        <img src={option.oneIcon} alt={option.titre} />
                                                    </figure>
                                                </div>
                                                <div className="block block2">
                                                    <h4 className="name">{option.titre}</h4>
                                                </div>
                                                <div className="block block3 mt-2 mb-2">
                                                    {option.description ? cleanHTML(option.description) : ''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : null}

                            {/* Modalités */}
                            {data?.modalites?.map((option) => (
                                <div className="candidate-block-three" key={option.id} style={{padding: 0}}>
                                    <div className="inner-box">
                                        <div className="content mb-1 mt-1">
                                            <div className="block block1">
                                                <figure className="image">
                                                    <img src={option.oneIcon} alt={option.titre}/>
                                                </figure>
                                            </div>
                                            <div className="block block2">
                                                <h4 className="name">{option.titre}</h4>
                                            </div>
                                            <div className="block block3 mt-2 mb-2">
                                                {option.description ? cleanHTML(option.description) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* Prix */}
                            {data?.prix?.map((option) => (
                                <div className="candidate-block-three" key={option.id} style={{padding: 0}}>
                                    <div className="inner-box">
                                        <div className="content mb-1 mt-1">
                                            <div className="block block1">
                                                <figure className="image">
                                                    <img src={option.oneIcon} alt={option.titre}/>
                                                </figure>
                                            </div>
                                            <div className="block block2">
                                                <h4 className="name">{option.titre}</h4>
                                            </div>
                                            <div className="block block3 mt-2 mb-2">
                                                {option.description ? cleanHTML(option.description) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/*<div className="sidebar-widget social-media-widget">
                    <h4 className="widget-title">Partager</h4>
                    <div className="widget-content">
                        <div className="social-links">
                            <Social url={`/nos-formations/${formationId}/${formationSlug}`} title={data?.titre} description={data?.description} />
                        </div>
                    </div>
                </div>*/}
            </>
    );
};

export default FormationPageSidebar;
