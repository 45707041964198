import React from 'react';
import Home7 from "../../components/home-7";

const HomePage = () => {
    return (
            <>
                <Home7 />
            </>
    );
};

export default HomePage;
