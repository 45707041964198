import CopyrightFooter from "./CopyrightFooter";
import {Link} from "react-router-dom";
import ScrollToTop from "../../common/ScrollTop";
import React from "react";
import ContactModal from "../../ContactModal";
import FooterContent4 from "../FooterContent4";

const index = ({ footerStyle = "" }) => {
  return (
    <footer className={`main-footer ${footerStyle}`}>
      <div className="auto-container">
        {/* <!--Widgets Section--> */}
        <div className="widgets-section" data-aos="fade-up">
          <div className="row">
            <div className="big-column col-xl-4 col-lg-3 col-md-12">
              <div className="footer-column about-widget">
                <div className="logo">
                  <Link to="/">
                    <img
                      width={200}
                      src="/images/Logo-THF-web.png"
                      alt="brand"
                    />
                  </Link>
                </div>
                <p className="address">
                  Talents Handicap Formation est un service Groupe Talents Handicap qui organise également les forums en ligne de l’emploi Talents Handicap.
                </p>
                <p className="question">
                  Vous avez une question ou une demande ?
                </p>
                <p className="address"></p>
                <div className="btn-box" style={{marginTop: "10px"}}>
                  <ContactModal textButton="Contactez-nous" showText={false} small="small" linkPageContact={true}/>
                </div>
              </div>
            </div>
            {/* End footer left widget */}

            <div className="big-column col-xl-8 col-lg-9 col-md-12">
              <div className="row">
                <FooterContent4 />
              </div>
            </div>
            {/* End col-xl-8 */}
          </div>
        </div>
      </div>
      {/* End auto-container */}

      <CopyrightFooter />
      <ScrollToTop />
      {/* <!--Bottom--> */}
    </footer>
    //   {/* <!-- End Main Footer --> */}
  );
};

export default index;
